<template>
    <div class="bg-themed">
        <div class="access-section slide-out-section py-6 pr-0 pl-1" :class="{'pr-6' : showAside}">
            <hb-data-table :headers="headers" :items="paymentCycles" :loading="loading">
            </hb-data-table>
        </div>
    </div>
</template>


<script type="text/babel">

import Loader from '../assets/CircleSpinner.vue';
import api from '../../assets/api.js';
import {notificationMixin} from "../../mixins/notificationMixin";

export default {
    name: "PaymentCyclesList",
    mixins: [ notificationMixin ],
    data() {
        return {
            paymentCycles: [],
            headers: [
                { text: 'Property Number', value: 'property_number' },
                { text: 'Space Number', value: 'unit_number' },
                { text: 'Payment Cycle', value: 'payment_cycle' },
                { text: 'Start Date', value: 'start_date' },
                { text: 'End Date', value: 'end_date' },
                { text: 'Status', value: 'status' },
                { text: 'Deleted', value: 'deleted' },
                { text: 'Deleted By', value: 'deleted_by' }
            ],
            loading: false
        }
    },
    components: {
        Loader
    },
    props: {
        contact: {
            type:Object,
            required: true
        },
        showAside: {
            type: Boolean
        },
    },
    created() {
        this.fetchPaymentCycles();
    },
    methods: {
        async fetchPaymentCycles() {
            this.loading = true;
            try {
              let response = await api.get(this, api.CONTACTS + this.contact.id + '/payment-cycles');
              this.paymentCycles = response;
            }catch (e) {
              this.showMessageNotification({type: 'error', description: e});
              console.log(e)
            }finally {
              this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
.bg-themed {
    background-color: #F9FAFB;
}
.access-section {
    max-height: 45vh;
    height: 45vh;
}
</style>
